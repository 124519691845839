import classNames from "classnames";
import routes from "config/routes";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
  return (
    <menu className="main-menu">
      <ul>
        {routes.admin.map((item, k) => {
          const { path, title, children, inMenu } = item;
          if (!inMenu) {
            return null;
          }
          return (
            <li key={k}>
              {typeof path !== "undefined" ? (
                <NavLink
                  to={`${path}`}
                  className={(navData) => {
                    return classNames("a-item", {
                      "has-children": children && children.length,
                      current: navData.isActive,
                    });
                  }}
                >
                  {title}
                </NavLink>
              ) : (
                <span
                  className={classNames("a-item", {
                    "has-children": children && children.length,
                  })}
                >
                  {title}
                </span>
              )}
              {children && children.length ? (
                <ul>
                  {children.map((itemSec, j) => {
                    const { path, title, separator } = itemSec;
                    if (separator) {
                      return (
                        <li key={j}>
                          <hr className="m-0" />
                        </li>
                      );
                    }
                    return (
                      <li key={j}>
                        {path ? (
                          <NavLink to={`${path}`} className="a-item">
                            {title}
                          </NavLink>
                        ) : (
                          <span className="a-item">{title}</span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </menu>
  );
};

export default MainMenu;
