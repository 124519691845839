import { api, setAuth } from "../utils";

const InfoPageService = {
  list: (params) => {
    setAuth();
    return api.get("info_pages", params);
  },
  deleteById: (params) => {
    setAuth();
    return api.post("info_pages/delete", params);
  },
  generate: (props) => {
    setAuth();
    return api.get("generate_info_pages/", props.data);
  },
  generateById: (data) => {
    setAuth();
    return api.post("generate_info_pages/", data);
  },
};

export default InfoPageService;
