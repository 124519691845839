import { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { S3_URL } from "config/variables";
import Icon from "components/icon";
import Table from "components/table";
import { Input } from "components/form";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
//
const years = [];
const d = new Date();
const yyyy = d.getFullYear();
let yy = 2016;
while (yy <= yyyy) {
  years.push(`${yy}`);
  yy++;
}

years.sort((a, b) => {
  return a < b ? 1 : -1;
});

const GenerateVisual = ({
  generate,
  data,
  loading,
  errors,
  onCancel,
  setLoadedNew,
}) => {
  const [dateMonth, setDateMonth] = useState("");
  const [dateYear, setDateYear] = useState("");

  useEffect(() => {
    const d = new Date();
    let mm = d.getMonth();
    mm = `${mm < 10 ? "0" + mm : mm}`;
    const yyyy = `${d.getFullYear()}`;
    setDateMonth(mm);
    setDateYear(yyyy);
  }, []);

  useEffect(() => {
    setLoadedNew(data && data.data && data.data.length);
  }, [setLoadedNew, data]);

  return (
    <div className="generate-container">
      <div className="text-center">
        <h2>Justificantes de aportación</h2>

        <div className="generate-date">
          <div className="generate-date_label">Mes:</div>

          <Row className="g-0">
            <Col className="pe-1" xs={7}>
              <Input
                type="select"
                value={dateMonth}
                onChange={setDateMonth}
                size="sm"
                options={months.map((text, k) => {
                  return {
                    text,
                    value: `${k + 1 < 10 ? "0" + (k + 1) : k + 1}`,
                  };
                })}
              />
            </Col>
            <Col xs={5}>
              <Input
                type="select"
                value={dateYear}
                onChange={setDateYear}
                size="sm"
                options={years.map((text) => {
                  return {
                    text,
                    value: text,
                  };
                })}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="generate-table-container">
        <Table
          data={data}
          loading={loading}
          errors={errors}
          noItemsText={"Sin justificantes de aportación generados"}
          model={[
            {
              name: "pharmacy_name",
              title: "Farmacia",
              size: 2,
              sort: true,
            },
            {
              name: "pharmacy_viaName",
              title: "Dirección",
              size: 3,
              sort: true,
              render: (item) => {
                return `${item?.pharmacy_viaType} ${item?.pharmacy_viaName} ${item?.pharmacy_num_via}`;
              },
            },
            {
              name: "pharmacy_location",
              title: "Localidad",
              size: 3,
              sort: true,
              render: (item) => {
                return (
                  <>
                    <div>{`${item?.pharmacy_location}`}</div>
                    <div>
                      {`(${item?.pharmacy_province}, ${item?.pharmacy_ccaa})`}
                    </div>
                    <div></div>
                  </>
                );
              },
            },
            {
              name: "pharmacy_cuota",
              title: "Cuota",
              size: 2,
              sort: true,
              render: (item, value) => {
                return value || "-";
              },
            },

            {
              name: "url",
              title: "Descargar",
              size: 2,
              //
              render: (item, value) => {
                return (
                  <Button
                    href={S3_URL + value}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                    color="danger"
                  >
                    <Icon type="file-pdf-o" className="me-1" /> PDF
                  </Button>
                );
              },
            },
          ]}
        />
      </div>
      <div className="text-center">
        {data && data.data ? (
          <h5 className="mb-4 italic">
            {`${data.data.length} Justificante${
              data.data.length === 1 ? "" : "s"
            } generado${data.data.length === 1 ? "" : "s"}`}
          </h5>
        ) : null}
        <Button
          color="cancel"
          size="lg"
          className="px-4 me-2"
          onClick={() => {
            onCancel();
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          size="lg"
          className="px-4"
          disabled={loading}
          onClick={() => {
            generate({
              data: {
                month: `${dateYear}-${dateMonth}`,
              },
            });
          }}
        >
          <Icon type="file-pdf-o" className="me-1" /> Generar Justificantes
        </Button>
      </div>
    </div>
  );
};
export default GenerateVisual;
