import AdminRoutes from "./admin";
import SignRoutes from "./sign";

const setPlainRoutes = (routes) => {
  const list = [];

  const setRoute = (routeList, pathRoute) => {
    routeList.forEach((route) => {
      const path = pathRoute + route.path;
      list.push({
        ...route,
        path,
      });
      if (route.childs) {
        setRoute(route.childs, path);
      }
    });
  };

  setRoute(routes, "");

  return list;
};

const routes = {
  admin: setPlainRoutes(AdminRoutes),
  sign: setPlainRoutes(SignRoutes),
};

export default routes;
