import { useApi, JustAportacionService } from "api";
import GenerateByIdVisual from "views/just_aportacion/generateById";

const GenerateById = ({ onCancel, setLoadedNew, idList }) => {
  const [generateById, data, loading, errors] = useApi({
    promise: JustAportacionService.generateById,
    initialState: { data: [] },
  });

  return (
    <GenerateByIdVisual
      generate={() => {
        generateById({ idList });
      }}
      idList={idList}
      data={data}
      loading={loading}
      errors={errors}
      onCancel={onCancel}
      setLoadedNew={setLoadedNew}
    />
  );
};
export default GenerateById;
