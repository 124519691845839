import { api, setAuth } from "../utils";

const ExecutionReportService = {
  list: (params) => {
    setAuth();
    return api.get("execution_reports", params);
  },
  deleteById: (params) => {
    setAuth();
    return api.post("execution_reports/delete", params);
  },
  generate: (props) => {
    setAuth();
    return api.get("generate_execution_reports/", props.data);
  },
  generateById: (data) => {
    setAuth();
    return api.post("generate_execution_reports/", data);
  },
};

export default ExecutionReportService;
