const UserAvatar = ({ size = "sm", src, className, username }) => {
  return (
    <div className={`avatar ${size} ${className}`}>
      {src ? (
        <img src={src} alt="" />
      ) : (
        <div className="avatar-letter">{username.charAt(0)}</div>
      )}
    </div>
  );
};

export default UserAvatar;
