import SignInView from "views/sign_in";
import { useApi, AuthService } from "api";
import { useNavigate } from "react-router-dom";
import storage from "utils/storage";

const SignIn = () => {
  let navigate = useNavigate();

  const [onSubmit, , loading, errors] = useApi({
    promise: AuthService.login,
    afterLoad: (data) => {
      if (data) {
        if (data.user) {
          storage.user.set(data.user);
        }
        if (data.token) {
          storage.auth.setToken(data.token);
          navigate("/");
        }
      }
    },
  });

  return <SignInView onSubmit={onSubmit} loading={loading} errors={errors} />;
};
export default SignIn;
