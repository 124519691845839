import { api, setAuth } from "../utils";

const AgentService = {
  list: () => {
    setAuth();
    return api.get("agents");
  },
};

export default AgentService;
