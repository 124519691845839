import { storageVariables } from "config/variables";

const storage = {
  get: () => {
    if (window) {
      const dataString = window.localStorage.getItem(storageVariables.name);
      if (dataString) {
        return JSON.parse(dataString);
      }
    }
    return null;
  },
  set: (data) => {
    if (window && data) {
      window.localStorage.setItem(storageVariables.name, JSON.stringify(data));
    }
  },
  clear: () => {
    if (window) {
      window.localStorage.removeItem(storageVariables.name);
    }
  },
};

const defaultModel = {
  auth: {
    token: null,
    expires: 0,
  },
};

storage.auth = {
  setToken: (value) => {
    const store = storage.get() || { ...defaultModel };
    store.auth.token = value;
    const d = new Date();
    store.auth.expires =
      d.getTime() + 86400000 * storageVariables.daysExpireToken;
    storage.set(store);
  },
  getToken: () => {
    const store = storage.get();
    if (!store) {
      return null;
    }
    return store.auth.token;
  },
  get: () => {
    const store = storage.get();
    if (!store) {
      return null;
    }
    return store.auth;
  },
};

storage.user = {
  set: (value) => {
    const store = storage.get() || { ...defaultModel };
    store.user = value;
    storage.set(store);
  },
  get: () => {
    const store = storage.get();
    if (!store) {
      return null;
    }
    return store.user;
  },
};

export default storage;
