import { useState, useEffect, useRef } from "react";
import { Form, Input } from "components/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Row } from "reactstrap";
import classNames from "classnames";
import Icon from "components/icon";

const DateToString = (date) => {
  if (!date) {
    return "";
  }
  let dd = date.getDate();
  dd = dd <= 9 ? "0" + dd : dd;
  let mm = date.getMonth() + 1;
  mm = mm <= 9 ? "0" + mm : mm;
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

const Filters = ({ filters, setFilters, agents, showFilterByType }) => {
  const [agentList, setAgentList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fixed, setFixed] = useState(false);
  const [mobileVisible, setMobileVisible] = useState(false);

  const filterDiv = useRef(null);

  useEffect(() => {
    if (filters.from) {
      setFromDate(new Date(filters.from));
    } else {
      setFromDate(null);
    }
    if (filters.to) {
      setToDate(new Date(filters.to));
    } else {
      setToDate(null);
    }
  }, [filters]);

  useEffect(() => {
    const toggleFixed = () => {
      if (filterDiv.current) {
        const top = filterDiv.current.getBoundingClientRect().top;

        if (top <= 0 && !fixed) {
          setFixed(true);
        }
        if (top > 0 && fixed) {
          setFixed(false);
        }
      }
    };
    if (window) {
      toggleFixed();
      window.addEventListener("scroll", toggleFixed);
    }

    return () => {
      if (window) {
        window.removeEventListener("scroll", toggleFixed);
      }
    };
  }, [fixed, filterDiv]);

  useEffect(() => {
    if (agents.data.length) {
      const list = agents.data.map((agent) => {
        return { value: agent.name, text: agent.name };
      });
      setAgentList(list);
    }
  }, [agents]);

  return (
    <div className="filters-container" ref={filterDiv}>
      <Button
        color="cancel"
        className="filter-mobile-btn"
        onClick={() => {
          setMobileVisible((v) => !v);
        }}
      >
        <Icon type="filter" className="me-2" />
        Filtros
        <Icon type="caret-down" className="ms-2" />
      </Button>
      <div
        className={classNames("filters-container-float", {
          fixed,
          mobileVisible,
        })}
      >
        <Form
          onSubmit={(searchValue) => {
            const newFilters = {
              ...filters,
              ...searchValue,
            };
            if (newFilters.search === "") {
              delete newFilters.search;
            }
            delete newFilters.page;
            setFilters(newFilters);
          }}
        >
          <Input
            data={{ search: filters.search || "" }}
            name="search"
            placeholder="Buscar..."
            size="lg"
            icon="search"
          />
        </Form>
        <hr />
        <h6 className="text-center muted">Filtrar por fecha</h6>
        <Row>
          <Col xl={6}>
            <label className="form-label">Desde</label>
            <DatePicker
              selected={fromDate}
              maxDate={toDate}
              onChange={(date) => {
                const from = DateToString(date);
                const newFilters = {
                  ...filters,
                  from,
                };
                if (newFilters.from === "") {
                  delete newFilters.from;
                }
                delete newFilters.page;
                setFilters(newFilters);
              }}
              className="form-control"
              placeholderText="DD-MM-YYYY"
              dateFormat="dd-MM-yyyy"
            />
          </Col>
          <Col xl={6}>
            <label className="form-label">Hasta</label>
            <DatePicker
              selected={toDate}
              minDate={fromDate}
              onChange={(date) => {
                const to = DateToString(date);
                const newFilters = {
                  ...filters,
                  to,
                };
                if (newFilters.to === "") {
                  delete newFilters.to;
                }
                delete newFilters.page;
                setFilters(newFilters);
              }}
              className="form-control"
              placeholderText="DD-MM-YYYY"
              dateFormat="dd-MM-yyyy"
            />
          </Col>
        </Row>
        <hr />
        {showFilterByType ? (
          <>
            <h6 className="text-center muted">Filtrar por tipo</h6>

            <Input
              data={{ type: filters.type || "" }}
              onChange={(v) => {
                const newFilters = {
                  ...filters,
                  type: v,
                };
                if (newFilters.type === "") {
                  delete newFilters.type;
                }
                delete newFilters.page;
                setFilters(newFilters);
              }}
              type="select"
              name="type"
              options={[
                { value: "AUTO", text: "Automático" },
                { value: "MANUAL", text: "Manual" },
              ]}
            />
            <hr />
          </>
        ) : null}
        <h6 className="text-center muted">Filtrar por agente</h6>

        <Input
          data={{ agent: filters.agent || "" }}
          onChange={(v) => {
            const newFilters = {
              ...filters,
              agent: v,
            };
            if (newFilters.agent === "") {
              delete newFilters.agent;
            }
            delete newFilters.page;
            setFilters(newFilters);
          }}
          type="select"
          name="agent"
          options={agentList}
        />
        <hr />
        <div className="text-center">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              const newFilters = {
                ...filters,
              };
              delete newFilters.search;
              delete newFilters.from;
              delete newFilters.to;
              delete newFilters.type;
              delete newFilters.agent;
              setFilters(newFilters);
            }}
          >
            Limpiar filtros
          </a>
        </div>
      </div>
    </div>
  );
};
export default Filters;
