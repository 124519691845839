import { api, setAuth } from "../utils";

const PayCheckService = {
  list: (params) => {
    setAuth();
    return api.get("pay_checks", params);
  },
  deleteById: (params) => {
    setAuth();
    return api.post("pay_checks/delete", params);
  },
  generate: (props) => {
    setAuth();
    return api.get("generate_pay_checks/", props.data);
  },
  generateById: (data) => {
    setAuth();
    return api.post("generate_pay_checks/", data);
  },
};

export default PayCheckService;
