//import Error404 from "routes/error404";
import SignIn from "containers/sign_in";

const SignRoutes = [
  {
    path: "sign-in",
    element: <SignIn />,
  },
  // {
  //   path: "up",
  //   element: <div>SIGN UP</div>,
  // },
  // {
  //   path: "*",
  //   element: <Error404 />,
  // },
];

export default SignRoutes;
