import Home from "containers/home";
import Reports from "containers/reports";
import InfoPages from "containers/info_pages";
import ExecutionReports from "containers/execution_reports";
import PayChecks from "containers/pay_checks";
import JustAportacion from "containers/just_aportacion";
import Error404 from "views/error404";

const AdminRoutes = [
  {
    id: "home",
    path: "/",
    title: "Home",
    icon: "home",
    inMenu: false,
    element: <Home />,
  },
  {
    id: "reports",
    path: "/project_reports",
    title: "Reportes de proyectos",
    icon: "reports",
    inMenu: true,
    element: <Reports />,
  },
  {
    id: "info_pages",
    path: "/info_pages",
    title: "Hojas informativas",
    icon: "info_pages",
    inMenu: true,
    element: <InfoPages />,
  },
  {
    id: "execution_reports",
    path: "/execution_reports",
    title: "Informes de ejecución",
    icon: "execution_reports",
    inMenu: true,
    element: <ExecutionReports />,
  },
  {
    id: "pay_checks",
    path: "/pay_checks",
    title: "Cheques",
    icon: "pay_checks",
    inMenu: true,
    element: <PayChecks />,
  },
  {
    id: "just_aportacion",
    path: "/proof_contribution",
    title: "Justificantes de aportación",
    icon: "just_aportacion",
    inMenu: true,
    element: <JustAportacion />,
  },
  {
    id: "error404",
    path: "*",
    title: "Error 404",
    element: <Error404 />,
  },
];

export default AdminRoutes;
