import classNames from "classnames";
import Icon from "components/icon";

const CheckboxClick = ({ checked, onClick = () => {} }) => {
  return (
    <div
      className={classNames("checkbox-click", { checked })}
      onClick={onClick}
    >
      <Icon type="check" />
    </div>
  );
};

export default CheckboxClick;
