import { create } from "apisauce";
import Qs from "qs";
import storage from "utils/storage";
import { apiBaseURL } from "config/variables";

export const api = create({
  timeout: 60000,
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: "repeat" });
  },
  baseURL: apiBaseURL,
  headers: {
    Accept: "application/json",
    "content-Type": "application/json",
  },
});

export const setAuth = () => {
  if (!api.headers["access-token"]) {
    const token = storage.auth.getToken();
    if (token) {
      api.setHeaders({
        "access-token": token,
      });
    }
  }
};
