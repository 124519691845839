import { api, setAuth } from "../utils";

const JustAportacionService = {
  list: (params) => {
    setAuth();
    return api.get("just_aportacion", params);
  },
  deleteById: (params) => {
    setAuth();
    return api.post("just_aportacion/delete", params);
  },
  generate: (props) => {
    setAuth();
    return api.get("generate_just_aportacion/", props.data);
  },
  generateById: (data) => {
    setAuth();
    return api.post("generate_just_aportacion/", data);
  },
};

export default JustAportacionService;
