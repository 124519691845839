import { Button } from "reactstrap";

const DeleteByIdVisual = ({ deleteById, onCancel, loading }) => {
  return (
    <div className="text-center">
      <h2 className="mb-4">¿Eliminar informe?</h2>
      <Button
        color="cancel"
        size="lg"
        className="px-4 me-2"
        onClick={() => {
          onCancel();
        }}
      >
        Cancelar
      </Button>

      <Button
        color="danger"
        size="lg"
        className="px-4"
        disabled={loading}
        onClick={deleteById}
      >
        Eliminar
      </Button>
    </div>
  );
};
export default DeleteByIdVisual;
