import { api, setAuth } from "../utils";

const ReportService = {
  list: (params) => {
    setAuth();
    return api.get("project_reports", params);
  },
  deleteById: (params) => {
    setAuth();
    return api.post("project_reports/delete", params);
  },
  generate: (props) => {
    setAuth();
    return api.get("generate_reports/" + props.type.toLowerCase(), props.data);
  },
  generateById: (data) => {
    setAuth();
    return api.post("generate_reports/", data);
  },
};

export default ReportService;
