import { useApi, JustAportacionService } from "api";
import DeleteByIdVisual from "views/just_aportacion/deleteById";

const DeleteByIdContainer = ({ onCancel, onReload, idToDelete }) => {
  const [deleteById, , loading, errors] = useApi({
    promise: JustAportacionService.deleteById,
    afterLoad: () => {
      onReload();
    },
  });

  return (
    <DeleteByIdVisual
      deleteById={() => {
        deleteById({ idList: [idToDelete] });
      }}
      loading={loading}
      errors={errors}
      onCancel={onCancel}
    />
  );
};
export default DeleteByIdContainer;
