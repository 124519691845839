import { useState, useEffect } from "react";
import routes from "config/routes";
import { NavLink } from "react-router-dom";
import Icon from "components/icon";
import { Col, Row } from "reactstrap";

const Breadcrumbs = ({ breadcrumbs }) => {
  const [breadcrumbList, setBreadcrumbList] = useState([]);

  useEffect(() => {
    if (breadcrumbs) {
      const list = [];
      breadcrumbs.forEach((breadId) => {
        const routeArray = routes.admin.filter((r) => {
          return r.id === breadId;
        });
        if (routeArray[0]) {
          const { path, title, id } = routeArray[0];
          list.push({ path, title, id });
        }
      });
      setBreadcrumbList(list);
    }
  }, [breadcrumbs]);

  return breadcrumbs ? (
    <div className="main-breadcrumbs">
      <Row className="g-0 align-items-center">
        <Col xs="auto">
          <NavLink className="main-breadcrumbs-item" to="/">
            <Icon type="home" />
          </NavLink>
          <div className="main-breadcrumbs-chevron">
            <Icon type="chevron-right" />
          </div>
        </Col>
        {breadcrumbList.map((b) => {
          return (
            <Col xs="auto" key={b.id}>
              <NavLink className="main-breadcrumbs-item" to={`/${b.path}`}>
                {b.title}
              </NavLink>
              <div className="main-breadcrumbs-chevron">
                <Icon type="chevron-right" />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : null;
};
export default Breadcrumbs;
