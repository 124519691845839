import { useEffect } from "react";
import JustAportacionView from "views/just_aportacion";
import { useApi, JustAportacionService, AgentService } from "api";
import { useSearchParams } from "react-router-dom";
import { URLSearchParamsToObject } from "utils";

const JustAportacion = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [fetchData, data, loading, errors] = useApi({
    promise: JustAportacionService.list,
    //initialState: [],
  });

  useEffect(() => {
    const filters = URLSearchParamsToObject(searchParams);
    fetchData(filters);
  }, [fetchData, searchParams]);

  const [fetchAgents, agents] = useApi({
    promise: AgentService.list,
    initialState: { data: [] },
  });

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return (
    <JustAportacionView
      filters={URLSearchParamsToObject(searchParams)}
      setFilters={setSearchParams}
      data={data}
      loading={loading}
      errors={errors}
      fetchData={fetchData}
      agents={agents}
      fetchAgents={fetchAgents}
    />
  );
};
export default JustAportacion;
