import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignRoutes from "config/routes/sign";
import UserAvatar from "components/avatar";
import Icon from "components/icon";
import storage from "utils/storage";

const UserHeader = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");

  useEffect(() => {
    const user = storage.user.get();
    setUsername(user?.name);
  }, []);

  return (
    <div className="main-user">
      <div className="main-user-avatar">
        <UserAvatar className="pointer" username={username} />
      </div>

      <div className="main-user-menu">
        {username ? (
          <div className="main-user-menu-name">{username}</div>
        ) : null}
        <a
          className="a-item a-sign-out"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            storage.clear();
            navigate("/" + SignRoutes[0].path);
          }}
        >
          <Icon type="sign-out" />
          Salir
        </a>
      </div>
    </div>
  );
};
export default UserHeader;
