export const formatDateFromApi = (dateApi) => {
  if (!dateApi) {
    return "-";
  }
  const arrDate = dateApi.split("T")[0].split("-");
  return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
};

export const URLSearchParamsToObject = (searchParams) => {
  const o = {};
  for (const [key, value] of searchParams) {
    o[key] = value;
  }
  return o;
};

export const moneyFormatter = (num) => {
  if (typeof num === "number") {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "EUR",
    }).format(num);
  } else {
    return num;
  }
};
