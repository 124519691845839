import { useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { formatDateFromApi } from "utils";
import { S3_URL } from "config/variables";
import Icon from "components/icon";
import Table from "components/table";

const GenerateByIdVisual = ({
  generate,
  data,
  idList = [],
  loading,
  errors,
  onCancel,
  setLoadedNew,
}) => {
  useEffect(() => {
    setLoadedNew(data.data.length);
  }, [setLoadedNew, data]);

  return (
    <div className="generate-container">
      <div className="text-center">
        <h2 className="mb-4">
          {data.data.length
            ? `Hoja${data.data.length === 1 ? "" : "s"} generada${
                data.data.length === 1 ? "" : "s"
              }`
            : `¿Regenerar${
                idList.length === 1 ? " " : ` ${idList.length} `
              }hoja${idList.length === 1 ? " " : "s "}infomativa${
                idList.length === 1 ? "" : "s"
              }?`}
        </h2>
      </div>

      <div className="generate-table-container">
        <Table
          data={data}
          loading={loading}
          errors={errors}
          noItemsText={"Sin hojas regeneradas"}
          model={[
            {
              name: "project_name",
              title: "Proyecto",
              size: 4,

              render: (item, value) => {
                return (
                  <div>
                    <Row className="align-items-center g-0">
                      <Col xs={4}>
                        <div className="table-logo">
                          <img src={item.entity_logo} alt="" />
                        </div>
                      </Col>
                      <Col xs={8}>
                        <div className="ps-3">{value}</div>
                      </Col>
                    </Row>
                  </div>
                );
              },
            },
            {
              name: "pharmacy_name",
              title: "Farmacia",
              size: 3,
            },
            {
              name: "date_end",
              title: "Fecha final",
              size: 3,

              render: (item, value) => {
                return formatDateFromApi(value);
              },
            },
            {
              name: "url",
              title: "Descargar",
              size: 2,
              //
              render: (item, value) => {
                return (
                  <Button
                    href={S3_URL + value}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                    color="danger"
                  >
                    <Icon type="file-pdf-o" className="me-1" /> PDF
                  </Button>
                );
              },
            },
          ]}
        />
      </div>
      <div className="text-center">
        {data.data.length ? (
          <h5 className="mb-4 italic">
            {`${data.data.length} Hoja${
              data.data.length === 1 ? "" : "s"
            } generada${data.data.length === 1 ? "" : "s"}`}
          </h5>
        ) : null}
        {!data.data.length ? (
          <Button
            color="cancel"
            size="lg"
            className="px-4 me-2"
            onClick={() => {
              onCancel();
            }}
          >
            Cancelar
          </Button>
        ) : null}
        <Button
          color="primary"
          size="lg"
          className="px-4"
          disabled={loading}
          onClick={() => {
            if (data.data.length) {
              onCancel();
            } else {
              generate();
            }
          }}
        >
          {data.data.length ? (
            "Cerrar"
          ) : (
            <>
              <Icon type="file-pdf-o" className="me-1" /> Regenerar Hoja
              {idList.length === 1 ? "" : "s"}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
export default GenerateByIdVisual;
