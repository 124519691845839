import TableUI from "./table_ui";
import Pagination from "components/pagination";
import classNames from "classnames";
import { LoadingBox } from "components/loading";
import { useCallback } from "react";

const Table = ({
  model,
  data,
  actions = null,
  loading,
  filters,
  errors,
  noItemsText,
  onUpdateFilters = () => {},
}) => {
  const updateFilters = useCallback(
    (inputFilters) => {
      const newFilters = {
        ...filters,
        ...inputFilters,
      };
      onUpdateFilters(newFilters);
    },
    [filters, onUpdateFilters]
  );

  return (
    <div className={classNames("table-ui_wrap", { loading })}>
      <div className="table-ui_content">
        <TableUI
          model={model}
          list={data && data.data ? data.data : []}
          actions={actions}
          filters={filters}
          onSort={updateFilters}
          errors={errors}
          noItemsText={noItemsText}
        />

        <Pagination
          page={data && data.page ? data.page : 1}
          elementsPerPage={
            data && data.elementsPerPage ? data.elementsPerPage : 10
          }
          elementsTotal={data && data.elementsTotal ? data.elementsTotal : 9}
          setPage={updateFilters}
        />
      </div>
      {loading ? <LoadingBox /> : null}
    </div>
  );
};
export default Table;
