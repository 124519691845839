import { useEffect } from "react";
import PayChecksView from "views/pay_checks";
import { useApi, PayCheckService, AgentService } from "api";
import { useSearchParams } from "react-router-dom";
import { URLSearchParamsToObject } from "utils";

const PayChecks = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [fetchData, data, loading, errors] = useApi({
    promise: PayCheckService.list,
    //initialState: [],
  });

  useEffect(() => {
    const filters = URLSearchParamsToObject(searchParams);
    fetchData(filters);
  }, [fetchData, searchParams]);

  const [fetchAgents, agents] = useApi({
    promise: AgentService.list,
    initialState: { data: [] },
  });

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return (
    <PayChecksView
      filters={URLSearchParamsToObject(searchParams)}
      setFilters={setSearchParams}
      data={data}
      loading={loading}
      errors={errors}
      fetchData={fetchData}
      agents={agents}
      fetchAgents={fetchAgents}
    />
  );
};
export default PayChecks;
