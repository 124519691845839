import { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import classNames from "classnames";
import TableActions from "./table_actions";

const TableUI = ({
  model,
  list,
  onSort = () => {},
  filters,
  actions,
  errors,
  noItemsText = "Without items",
}) => {
  const modelProcessed = useMemo(() => {
    const modelComplete =
      actions && actions.length
        ? model.concat([
            {
              name: "$$actions$$",
              title: "null",
              size: 1,
            },
          ])
        : model;

    const newModel = [];
    let sizesTotal = 0;
    let countNoSized = 0;

    modelComplete.forEach((column) => {
      if (typeof column.size === "number") {
        sizesTotal += column.size;
      } else {
        countNoSized += 1;
      }
    });
    const sizeNormal = Math.floor((12 - sizesTotal) / countNoSized);

    let notSizedIndex = 1;
    modelComplete.forEach((column) => {
      const newColumn = { ...column };
      if (typeof column.size !== "number") {
        if (notSizedIndex < countNoSized) {
          newColumn.size = sizeNormal;
        }
        notSizedIndex += 1;
      }
      if (column.size === 0) {
        newColumn.size = "auto";
      }
      newModel.push(newColumn);
    });

    return newModel;
  }, [model, actions]);

  const setSort = useCallback(
    (name) => {
      let orderBy = name;
      let orderTo = "ASC";
      if (filters && filters.orderBy && name === filters.orderBy) {
        orderTo =
          filters && filters.orderTo && filters.orderTo === "ASC"
            ? "DESC"
            : "ASC";
      }
      onSort({
        orderBy,
        orderTo,
      });
    },
    [filters, onSort]
  );

  return (
    <div className="table-ui-container">
      <div className="table-ui">
        <div className="table-ui_header">
          <div className="table-ui_row">
            <Row className="g-0 align-items-center flex-nowrap">
              {modelProcessed.map((column) => {
                const { name, title, size, sort } = column;

                return (
                  <Col key={name} xs={size}>
                    <div
                      className={classNames("table-ui_col", {
                        sorteable: sort,
                      })}
                      onClick={() => {
                        if (sort) {
                          setSort(name);
                        }
                      }}
                    >
                      <Row className="g-0 align-items-center flex-nowrap">
                        {sort ? (
                          <Col xs="auto">
                            <div
                              className={classNames("table-ui_sort", {
                                "sort-asc":
                                  filters &&
                                  filters.orderBy &&
                                  filters.orderTo &&
                                  name === filters.orderBy &&
                                  filters.orderTo === "ASC",
                                "sort-desc":
                                  filters &&
                                  filters.orderBy &&
                                  filters.orderTo &&
                                  name === filters.orderBy &&
                                  filters.orderTo === "DESC",
                              })}
                            >
                              <div className="table-ui_sort-ic ic-asc" />
                              <div className="table-ui_sort-ic ic-desc" />
                            </div>
                          </Col>
                        ) : null}
                        <Col>{name !== "$$actions$$" ? title : null}</Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="table-ui_body">
          <div className="table-ui_body-container">
            {list.length ? (
              list.map((item, k) => {
                return (
                  <div
                    className="table-ui_row"
                    key={item && item.id ? item.id : k}
                  >
                    <Row className="g-0 align-items-center flex-nowrap">
                      {modelProcessed.map((column) => {
                        const { name, size, render, sort } = column;
                        const content = render
                          ? render(item, item[name])
                          : item[name]
                          ? item[name]
                          : null;
                        return (
                          <Col key={name} xs={size}>
                            <div
                              className={classNames("table-ui_col", {
                                "for-sort": sort,
                              })}
                            >
                              {name !== "$$actions$$" ? (
                                content
                              ) : (
                                <TableActions item={item} actions={actions} />
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })
            ) : errors ? (
              <div className="table-ui_error">{errors.error}</div>
            ) : (
              <div className="table-ui_no-items">{noItemsText}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableUI;
