const Footer = () => {
  const d = new Date();
  return (
    <div className="main-footer">
      <div className="main-container">
        <div className="footer-container">
          Copyright {d.getFullYear()} - FarmaSolidaria - Reportes de proyectos
        </div>
      </div>
    </div>
  );
};
export default Footer;
