import { useState } from "react";
import { Modal, Alert, Button } from "reactstrap";
import Logo from "components/logo";
import { LoadingBox } from "components/loading";
import { Form, Input } from "components/form";

const validations = {
  email: ["required", "email"],
  password: ["required"],
};

const SignInView = ({ loading, errors, onSubmit }) => {
  const [validationStatus, setValidationStatus] = useState({});

  return (
    <Modal isOpen centered backdrop={false}>
      <div className="sign">
        <div className="sign-header">
          <Logo />
        </div>
        <div className="sign-body">
          <div className="text-center mb-4">
            <h2 className="m-0 mb-2">Reportes de proyectos</h2>
            <h3 className="m-0 muted">Ingreso</h3>
          </div>

          <Form
            validations={validations}
            validationStatus={validationStatus}
            setValidationStatus={setValidationStatus}
            onSubmit={onSubmit}
          >
            <Input
              validations={validations}
              validationStatus={validationStatus}
              setValidationStatus={setValidationStatus}
              label="Email"
              name="email"
              type="email"
              placeholder="Email"
              size="lg"
              icon="user"
            />
            <Input
              validations={validations}
              validationStatus={validationStatus}
              setValidationStatus={setValidationStatus}
              label="Contraseña"
              name="password"
              type="password"
              size="lg"
              icon="key"
            />
            {errors ? (
              <Alert color="danger" className="text-center">
                Error, usuario y/o contraseña incorrectos.
              </Alert>
            ) : null}
            <div className="text-center py-4">
              <Button color="primary" size="lg" type="submit" className="px-5">
                Ingresar
              </Button>
            </div>
          </Form>
        </div>
        {loading ? <LoadingBox /> : null}
      </div>
    </Modal>
  );
};
export default SignInView;
