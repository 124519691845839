import { useApi, ExecutionReportService } from "api";
import GenerateVisual from "views/execution_reports/generate";

const Generate = ({ onCancel, setLoadedNew }) => {
  const [generate, data, loading, errors] = useApi({
    promise: ExecutionReportService.generate,
  });

  return (
    <GenerateVisual
      generate={generate}
      data={data}
      loading={loading}
      errors={errors}
      onCancel={onCancel}
      setLoadedNew={setLoadedNew}
    />
  );
};
export default Generate;
