import { useEffect } from "react";
import ReportsView from "views/reports";
import { useApi, ReportService, AgentService } from "api";
import { useSearchParams } from "react-router-dom";
import { URLSearchParamsToObject } from "utils";

const Reports = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [fetchData, data, loading, errors] = useApi({
    promise: ReportService.list,
    //initialState: [],
  });

  useEffect(() => {
    const filters = URLSearchParamsToObject(searchParams);
    fetchData(filters);
  }, [fetchData, searchParams]);

  const [fetchAgents, agents] = useApi({
    promise: AgentService.list,
    initialState: { data: [] },
  });

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return (
    <ReportsView
      filters={URLSearchParamsToObject(searchParams)}
      setFilters={setSearchParams}
      data={data}
      loading={loading}
      errors={errors}
      fetchData={fetchData}
      agents={agents}
      fetchAgents={fetchAgents}
    />
  );
};
export default Reports;
