//import { Outlet, Link, Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLayout from "layouts/admin";
import SignLayout from "layouts/sign";
import routes from "config/routes";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminLayout />}>
          {routes.admin.map((route, k) => {
            const { path, element } = route;
            return <Route path={path} element={element} key={k} />;
          })}
        </Route>
        <Route path="/auth" element={<SignLayout />}>
          {routes.sign.map((route, k) => {
            const { path, element } = route;
            return <Route path={path} element={element} key={k} />;
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
