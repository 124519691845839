import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
//import Header from "components/header";
import Header from "components/header";
import Footer from "components/footer";
import storage from "utils/storage";

const AdminLayout = () => {
  let navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const goToSignIn = () => {
      navigate("/auth/sign-in");
    };
    const auth = storage.auth.get();

    if (!auth) {
      goToSignIn();
    } else {
      const { expires } = auth;
      const d = new Date();
      const currentTime = d.getTime();
      if (currentTime > expires) {
        storage.clear();
        goToSignIn();
      } else {
        setEnabled(true);
      }
    }
  }, [navigate]);

  return enabled ? (
    <div className="main-admin">
      <div className="main-admin-container">
        {/* <Header /> */}
        <Header />
        <div className="main-container">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  ) : null;
};
export default AdminLayout;
