import Icon from "components/icon";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const TableActions = ({ actions, item }) => {
  return (
    <div className="table-ui_action">
      <UncontrolledDropdown>
        <DropdownToggle tag="div">
          <div className="table-ui_action-toggle">
            <i className="fa fa-ellipsis-h" aria-hidden="true" />
          </div>
        </DropdownToggle>
        <DropdownMenu end>
          {actions.map((action, k) => {
            const { title, onClick, className, icon, render, tag } = action;
            return (
              <DropdownItem
                key={k}
                className={className}
                tag={tag}
                onClick={() => {
                  if (onClick) onClick(item);
                }}
              >
                {render ? (
                  render(item)
                ) : (
                  <>
                    {icon ? <Icon type={icon} className="me-2" /> : null}
                    {title}
                  </>
                )}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
export default TableActions;
