import { useState, useEffect } from "react";
import Section from "components/section";
import { Card, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import Table from "components/table";
import { formatDateFromApi, moneyFormatter } from "utils";
import Icon from "components/icon";
import Filters from "components/filters";
import { S3_URL } from "config/variables";
import Generate from "containers/info_pages/generate";
import GenerateById from "containers/info_pages/generateById";
import DeleteById from "containers/info_pages/deleteById";
import CheckboxClick from "components/checkboxClick";

const ListView = ({
  filters,
  setFilters,
  data,
  agents,
  fetchAgents,
  loading,
  errors,
  fetchData,
}) => {
  const [showModalGenerate, setShowModalGenerate] = useState(false);
  const [regenerateIdList, setRegenerateIdList] = useState([]);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showModalReGenerate, setShowModalReGenerate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loadedNew, setLoadedNew] = useState(false);

  useEffect(() => {
    setRegenerateIdList([]);
  }, [data]);

  return (
    <Section>
      <header className="page-header">
        <h1>Hojas informativas</h1>
      </header>
      <Row>
        <Col lg="auto">
          <Filters filters={filters} agents={agents} setFilters={setFilters} />
        </Col>
        <Col>
          <Row className="justify-content-between align-items-end mb-3  ">
            <Col xs="auto">
              {regenerateIdList.length ? (
                <Button
                  color="primary"
                  size="sm"
                  className="btn-regenerate-bulk d-md-block d-none"
                  onClick={() => {
                    setShowModalReGenerate(true);
                  }}
                >
                  <Icon type="arrow-down" className="me-2" />
                  <Icon type="file-pdf-o" className="me-2" />
                  Regenerar hojas seleccionadas
                </Button>
              ) : null}
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                size="lg"
                className="px-4"
                onClick={() => {
                  setShowModalGenerate(true);
                }}
              >
                <Icon type="file-pdf-o" className="me-2" />
                Generar hojas por mes
              </Button>
            </Col>
          </Row>
          <Card>
            <Table
              data={data}
              filters={filters}
              loading={loading}
              errors={errors}
              model={[
                {
                  name: "selector_checkbox",
                  sort: false,
                  title: (() => {
                    const isChecked =
                      data &&
                      data.data &&
                      data.data.length &&
                      data.data.length === regenerateIdList.length;

                    return (
                      <CheckboxClick
                        checked={isChecked}
                        onClick={() => {
                          if (isChecked) {
                            setRegenerateIdList([]);
                          } else {
                            if (data && data.data && data.data.length) {
                              const list = data.data.map((itm) => {
                                return itm.id;
                              });
                              setRegenerateIdList(list);
                            }
                          }
                        }}
                      />
                    );
                  })(),
                  size: 0,
                  render: (item, value) => {
                    const index = regenerateIdList.indexOf(item.id);
                    const checked = index >= 0;
                    return (
                      <CheckboxClick
                        checked={checked}
                        onClick={() => {
                          if (checked) {
                            const listRemove = [...regenerateIdList];
                            listRemove.splice(index, 1);
                            setRegenerateIdList(listRemove);
                          } else {
                            const listAdd = regenerateIdList.concat([item.id]);
                            setRegenerateIdList(listAdd);
                          }
                        }}
                      />
                    );
                  },
                },
                {
                  name: "project_name",
                  title: "Proyecto",
                  size: 3,
                  sort: true,
                  render: (item, value) => {
                    return (
                      <div>
                        <Row className="align-items-center g-0">
                          <Col xs={4}>
                            <div className="table-logo">
                              <img src={item.entity_logo} alt="" />
                            </div>
                          </Col>
                          <Col xs={8}>
                            <div className="ps-3">{value}</div>
                          </Col>
                        </Row>
                      </div>
                    );
                  },
                },
                {
                  name: "entity_name",
                  title: "Entidad",
                  size: 2,
                  sort: true,
                },
                {
                  name: "pharmacy_name",
                  title: "Farmacia",
                  size: 2,
                  sort: true,
                },

                {
                  name: "date_end",
                  title: "Fecha final",
                  sort: true,
                  render: (item, value) => {
                    return formatDateFromApi(value);
                  },
                },
                {
                  name: "date_created",
                  title: "Fecha (generada)",

                  sort: true,
                  render: (item, value) => {
                    return formatDateFromApi(value);
                  },
                },
                {
                  name: "income_end",
                  title: "Importe recaudado",
                  size: 1,

                  sort: true,
                  render: (item, value) => {
                    return moneyFormatter(value);
                  },
                },
              ]}
              actions={[
                {
                  tag: "div",
                  render: (item) => {
                    return (
                      <Button
                        href={S3_URL + item.url}
                        target="_blank"
                        rel="noreferrer"
                        size="sm"
                        color="danger"
                        download
                        block
                      >
                        <Icon type="file-pdf-o" className="me-1" /> Descargar
                        PDF
                      </Button>
                    );
                  },
                },
                {
                  tag: "div",
                  render: (item) => {
                    return (
                      <Button
                        rel="noreferrer"
                        size="sm"
                        color="primary"
                        download
                        block
                        onClick={() => {
                          setRegenerateIdList([item.id]);
                          setShowModalReGenerate(true);
                        }}
                      >
                        <Icon type="refresh" className="me-1" /> Regenerar Hoja
                      </Button>
                    );
                  },
                },
                {
                  icon: "trash",
                  title: "Eliminar",
                  className: "text-danger",
                  onClick: (item) => {
                    setIdToDelete(item.id);
                    setShowModalDelete(true);
                  },
                },
              ]}
              onUpdateFilters={(f) => {
                setFilters(f);
              }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={showModalGenerate}
        toggle={() => {
          setShowModalGenerate(false);
          if (loadedNew) {
            fetchAgents();
            setFilters({});
            fetchData({});
          }
        }}
        centered
        className="mb-full"
      >
        <ModalBody>
          {showModalGenerate ? (
            <Generate
              onCancel={() => {
                setShowModalGenerate(false);
                if (loadedNew) {
                  fetchAgents();
                  setFilters({});
                  fetchData({});
                }
              }}
              setLoadedNew={setLoadedNew}
            />
          ) : null}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showModalReGenerate}
        toggle={() => {
          setShowModalReGenerate(false);
          setRegenerateIdList([]);
          if (loadedNew) {
            fetchAgents();
            fetchData(filters);
          }
        }}
        centered
        className="mb-full"
      >
        <ModalBody>
          {showModalReGenerate ? (
            <GenerateById
              onCancel={() => {
                setShowModalReGenerate(false);
                setRegenerateIdList([]);
                if (loadedNew) {
                  fetchAgents();
                  fetchData(filters);
                }
              }}
              setLoadedNew={setLoadedNew}
              idList={regenerateIdList}
            />
          ) : null}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showModalDelete}
        toggle={() => {
          setShowModalDelete(false);
          setRegenerateIdList([]);
          if (loadedNew) {
            fetchAgents();
            fetchData(filters);
          }
        }}
        centered
        //className="mb-full"
      >
        <ModalBody>
          {showModalDelete ? (
            <DeleteById
              onCancel={() => {
                setShowModalDelete(false);
                setRegenerateIdList([]);
              }}
              onReload={() => {
                setShowModalDelete(false);
                setRegenerateIdList([]);
                fetchAgents();
                fetchData(filters);
              }}
              idToDelete={idToDelete}
            />
          ) : null}
        </ModalBody>
      </Modal>
    </Section>
  );
};
export default ListView;
